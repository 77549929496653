import React, {useState} from 'react';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {AiModel} from "tobl-data-schema/dist/enums/AiModel";
import {useTranslation} from "react-i18next";
import {useGlobalContext} from "../../../hooks/GlobalContext";
import {BlockEditFormRow} from "./BlockEditFormRow";
import {TextAiConfigs} from "./TextAiConfigs";
import {
  BlockType_Processor,
  NewsSource
} from "tobl-data-schema/dist/schema/block/block_type/processor";
import Switch from "@mui/material/Switch";

export default function EditFormProcessorAiText(
  {
    configuration,
    updateConfigData,
  }: {
    configuration:
      TextAiConfigs,
    updateConfigData: (key: string, value: any) => void
  }) {
  const [charCount, setCharCount] = useState((configuration.prompt || "").length);
  const {t} = useTranslation();

  const globalContext = useGlobalContext();
  const isAdmin = globalContext.isAdmin;

  const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPrompt = e.target.value;
    setCharCount(newPrompt.length);
    updateConfigData('prompt', newPrompt);
  };

  const handleGptCustomModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCustomModelName = e.target.value;
    updateConfigData('customModelName', newCustomModelName);
  };

  return (
    <>
      <BlockEditFormRow label={t("AI Model")}>
        <Select
          value={configuration.aiModel || AiModel.GPT_3_5_TURBO}
          onChange={(e) => updateConfigData('aiModel', e.target.value)}
          fullWidth
          onClick={e => e.stopPropagation()}
        >
          <MenuItem value={AiModel.GPT_4O}>GPT-4O</MenuItem>
          <MenuItem value={AiModel.CLAUDE_3_OPUS}>Claude 3 Opus</MenuItem>
          <MenuItem value={AiModel.CLAUDE_3_SONNET}>Claude 3 Sonnet</MenuItem>
          <MenuItem value={AiModel.GPT_4_TURBO}>GPT-4 Turbo</MenuItem>
          <MenuItem value={AiModel.GPT_3_5_TURBO_16k}>GPT-3.5 Turbo
            16k</MenuItem>
          <MenuItem value={AiModel.GPT_3_5_TURBO}>GPT-3.5 Turbo</MenuItem>
          <MenuItem value={AiModel.GPT_4}>GPT-4</MenuItem>
          <MenuItem value={AiModel.GOOGLE_PALM2}>Google Palm 2</MenuItem>
          {isAdmin && <MenuItem value={AiModel.GPT_CUSTOM_MODEL}>GPT Custom
              Model</MenuItem>}
        </Select>
      </BlockEditFormRow>


      {configuration.blockType_Processor === BlockType_Processor.api_google_news_multi &&
          <BlockEditFormRow label={t("Create Image?")}>
              <Switch
                  checked={configuration.createImage || false}
                  onChange={(e) => updateConfigData('createImage', e.target.checked)}
              />
          </BlockEditFormRow>
      }

      {configuration.blockType_Processor === BlockType_Processor.api_google_news_multi && configuration.createImage &&
          <BlockEditFormRow label={t("Image Prompt")}>
              <TextField
                  key={`image-prompt-field`}
                  multiline
                  minRows={4}
                  maxRows={30}
                  onKeyDown={e => e.stopPropagation()}
                  onKeyUp={e => e.stopPropagation()}
                  value={configuration.imagePrompt || ""}
                  onChange={(e) => updateConfigData('imagePrompt', e.target.value)}
                  fullWidth
              />
          </BlockEditFormRow>
      }

      {configuration.blockType_Processor === BlockType_Processor.api_google_news_multi &&
          <BlockEditFormRow label={t("News Source")}>
              <Select
                  value={configuration.newsSource || NewsSource.google}
                  onChange={(e) => updateConfigData('newsSource', e.target.value)}
                  fullWidth
                  onClick={e => e.stopPropagation()}
              >
                {Object.keys(NewsSource).map((key) => (
                  <MenuItem value={NewsSource[key as keyof typeof NewsSource]}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
          </BlockEditFormRow>
      }

      <BlockEditFormRow label={t("Prompt")}>
        <TextField
          key={`prompt-field`}
          multiline
          minRows={4}
          maxRows={30}
          onKeyDown={e => e.stopPropagation()}
          onKeyUp={e => e.stopPropagation()}
          value={configuration.prompt || ""}
          onChange={handlePromptChange}
          fullWidth
          helperText={`${charCount} ${t("characters")}`}
          FormHelperTextProps={{sx: {textAlign: 'right'},}}
        />
      </BlockEditFormRow>

      {configuration.aiModel === AiModel.GPT_CUSTOM_MODEL &&
          <BlockEditFormRow label={t("gpt_custom_model")}>
              <TextField
                  key={`gpt_custom_model-field`}
                  onKeyDown={e => e.stopPropagation()}
                  onKeyUp={e => e.stopPropagation()}
                  value={configuration.customModelName || ""}
                  onChange={handleGptCustomModelChange}
                  fullWidth
              />
          </BlockEditFormRow>
      }

    </>
  );

}
